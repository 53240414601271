export class Pages{
    public static login = "Login";
    public static home = "Home";

    //ADMINISTRATIVO
     public static homeAdm = "HomeAdmin";
     public static userAdm = "UserAdmin";
     public static empresaAdm = "EmpresaAdmin";
     public static candidatoAdm = "CandidatoAdmin";
     public static contratadosAdm = "ContratadosAdmin";
     public static vagasAdm = "VagasAdmin";
     public static comunidadeAdm = "ComunidadeAdmin";
     public static logoEmpresaAdm = "LogoEmpresaAdmin";
     public static funcoesAdm = "FuncoesAdmin";
     public static termoAdm = "TermosAdmin";
     public static politicaAdm = "PoliticaAdmin";
     public static perfilAdm = "PerfilAdmin";
     public static changePasswordAdm = "ChangePasswordAdmin";
     //PARA CANDIDATOS
     public static criarContaCandidato = "CreateCandidato";
     public static loginCandidato = "LoginCandidato";
     public static homeCandidatos = "HomeCandidatos";
     public static inscricoesCandidatos = "InscricoesCandidatos";
     public static curriculoCandidatos = "CurriculoCandidatos";
     public static perfilCandidatos = "PerfilCandidatos";
     public static quemSomosCandidato = "QuemSomosCandidato";
     //PARA EMPRESAS
     public static criarContaEmpresas = "CreateEmpresa";
     public static loginEmpresa = "LoginEmpresa";
     public static homeEmpresas = "HomeEmpresa";
     public static perfilEmpresa = "PerfilEmpresa";
     public static criarVagaEmpresa = "CriarVagaEmpresa";
     public static editarVagaEmpresa = "EditarVagaEmpresa";
     public static visualizarVagaEmpresa = "VisualizarVagaEmpresa";
     public static inscritosEmVagaEmpresa = "InscritosEmVagaEmpresa";
     public static vagaCandidatoEmpresa = "VagaCandidatoEmpresa";
     public static quemSomosEmpresa = "QuemSomosEmpresa";
     //VAGAS
     public static vagas = "Vagas";
     public static detalheVaga = "DetalheVaga";
     //INFORMATIVO
     public static quemSomos = "QuemSomos";
     public static termoDeUso = "TermoDeUso";
     public static politicaPrivacidade = "PoliticaDePrivacidade";
     //SERVIÇOS
     public static cancelarEnvioEmail = "CancelarEmail";
     public static resetPassword = "ResetPassword";
}
