import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.CandidatoServiceProxy,
        ApiServiceProxies.EmpresaServiceProxy,
        ApiServiceProxies.AdministradorServiceProxy,
        ApiServiceProxies.TermosServiceProxy,
        ApiServiceProxies.EnumServiceProxy,
        ApiServiceProxies.CompetenciaServiceProxy,
        ApiServiceProxies.CargoServiceProxy,
        ApiServiceProxies.VagaServiceProxy,
        ApiServiceProxies.CandidatoVagaServiceProxy,
        ApiServiceProxies.CandidatoVagaAnotacaoServiceProxy,
        ApiServiceProxies.CurriculoDadoPessoalServiceProxy,
        ApiServiceProxies.CurriculoDadoPessoalAnexoServiceProxy,
        ApiServiceProxies.CandidatoIdiomaServiceProxy,
        ApiServiceProxies.CurriculoCompetenciaServiceProxy,
        ApiServiceProxies.IdiomaServiceProxy,
        ApiServiceProxies.CandidatoFormacaoAcademicaServiceProxy,
        ApiServiceProxies.CurriculoExperienciaServiceProxy,
        ApiServiceProxies.AdminDashboardServiceProxy,
        ApiServiceProxies.SkillConectaServiceProxy,
        ApiServiceProxies.CurriculoSkillConectaServiceProxy,
        ApiServiceProxies.LaudoMedicoServiceProxy,
        ApiServiceProxies.ComunidadeServiceProxy,
        ApiServiceProxies.MicrorregiaoServiceProxy,
        ApiServiceProxies.CidadeMicrorregiaoServiceProxy,
        ApiServiceProxies.OnboardingCandidatoServiceProxy,

        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
