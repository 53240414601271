import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BuscarCnpjService {

    getEmpresa(cnpj: any) {
        return new Observable((x) => {
            var request = new XMLHttpRequest();
            request.open('get', `${environment.apiCnpj}${cnpj}`, true);
            request.send();
            request.onload = function () {
                var data = JSON.parse(this.response);
                x.next(data);
            }
        });
    }

}
