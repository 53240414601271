<div [ngClass]="{'justify-content-between': !perfil, 'justify-content-start': perfil}" class="row mb-5">
    <div class="form-group col-md-5 mr-5">
        <label class="form-label" for="cepEndereco">Cep</label>
        <div>
            <input required placeholder="00.000-000" id="cepEndereco" type="text" class="form-control"
                name="cepEndereco" (blur)="onCepBlur()" minlength="8" mask="00000-000" [(ngModel)]="endereco.cep"
                (input)="onFieldChangeEndereco()"
                #cepEnderecoModel="ngModel" #cepEnderecoEl />
        </div>
    </div>

    <div class="form-group col-md-5">
        <label class="form-label required" for="estado">UF</label>
        <div>
            <ng-multiselect-dropdown [placeholder]="'Selecione um Estado'" [settings]="dropdownSettings"
                [data]="dropdownEstadosList" [(ngModel)]="selectedEstados" (onSelect)="onEstadoSelected($event)"
                (onDeSelect)="onEstadoDeSelected()">
            </ng-multiselect-dropdown>
        </div>
    </div>
</div>

<div [ngClass]="{'justify-content-between': !perfil, 'justify-content-start': perfil}" class="row mb-5">
    <div class="form-group col-md-5 mr-5">
        <label class="form-label required" for="cidade">Cidade</label>
        <div>
            <ng-multiselect-dropdown [disabled]="desabilitarCidade" [placeholder]="'Selecione uma cidade'"
                [settings]="dropdownSettings" [data]="dropdownCidadesList" [(ngModel)]="selectedCidades"
                (onSelect)="onCidadeSelected($event)" (onDeSelect)="onCidadeDeSelected()">
            </ng-multiselect-dropdown>
        </div>
    </div>

    <div class="form-group col-md-5">
        <label class="form-label required" for="logradouro">Logradouro</label>
        <div>
            <input required type="text" class="form-control" name="logradouro" id="logradouro" maxlength="256"
                [(ngModel)]="endereco.logradouro" (input)="onFieldChangeEndereco()" #logradouroModel="ngModel" #logradouroEl />
            <abp-validation-summary [control]="logradouroModel" [controlEl]="logradouroEl"></abp-validation-summary>
        </div>
    </div>
</div>

<div [ngClass]="{'justify-content-between': !perfil, 'justify-content-start': perfil}" class="row mb-5">
    <div class="form-group col-md-5 mr-5">
        <label class="form-label required" for="bairro">Bairro</label>
        <div>
            <input required type="text" class="form-control" name="bairro" id="bairro" maxlength="256"
                [(ngModel)]="endereco.bairro" (input)="onFieldChangeEndereco()" #bairroModel="ngModel" #bairroEl />
            <abp-validation-summary [control]="bairroModel" [controlEl]="bairroEl"></abp-validation-summary>
        </div>
    </div>

    <div class="form-group col-md-5">
        <label class="form-label" for="complemento">Complemento</label>
        <div>
            <input type="text" class="form-control" name="complemento" id="complemento" maxlength="256"
                [(ngModel)]="endereco.complemento" (input)="onFieldChangeEndereco()" #complementoModel="ngModel" #complementoEl />
            <abp-validation-summary [control]="complementoModel" [controlEl]="complementoEl"></abp-validation-summary>
        </div>
    </div>
</div>

<div class="row">
    <div class="form-group col-md-3">
        <label class="form-label" for="name">
            Número
        </label>
        <div>
            <input type="text" class="form-control" onlyAlphanumeric name="numero" id="numero" maxlength="20"
                [(ngModel)]="endereco.numero" (input)="onFieldChangeEndereco()" #numeroModel="ngModel" #numeroEl />
            <abp-validation-summary [control]="numeroModel" [controlEl]="numeroEl">
            </abp-validation-summary>
        </div>
    </div>
</div>
