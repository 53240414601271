import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

export interface ConsultaEstadosResponse {
  map(arg0: (estado: any) => { sigla: any; nome: any; }): ConsultaEstadosResponse[];
  sigla: string;
  nome: string;
}
export interface ConsultaCidadesResponse {
  map(arg0: (estado: any) => {nome: any; }): ConsultaEstadosResponse[];
  nome: string;
}

@Injectable({
  providedIn: "root",
})
export class ConsultaEstadosService {
  constructor(private http: HttpClient) { }

  public loadEstados(): Observable<ConsultaEstadosResponse> {
    return new Observable<ConsultaEstadosResponse>((observer) => {
      var request = new XMLHttpRequest();
      request.open("GET", `https://servicodados.ibge.gov.br/api/v1/localidades/estados`, true);
      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status === 200) {
            const data = JSON.parse(request.responseText);
            observer.next(data);
            observer.complete();
          } else {
            observer.error("Erro na consulta do CEP");
          }
        }
      };
      request.send();
    });
  }

  public consultaCidades(estado: string): Observable<ConsultaCidadesResponse> {
    return new Observable<ConsultaCidadesResponse>((observer) => {
      var request = new XMLHttpRequest();
      request.open("GET", `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`, true);
      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status === 200) {
            const data = JSON.parse(request.responseText);
            observer.next(data);
            observer.complete();
          } else {
            observer.error("Erro na consulta do CEP");
          }
        }
      };
      request.send();
    });
  }
}
