import { finalize, Subscription } from 'rxjs';
import { CandidatoDto, CreateOnboardingCandidatoDto, OnboardingCandidatoServiceProxy } from './../../../shared/service-proxies/service-proxies';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CandidatoService } from 'candidato/service/candidato.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss']
})
export class OnboardingModalComponent implements OnInit {
  idCandidato: string;
  onboarding: CreateOnboardingCandidatoDto = new CreateOnboardingCandidatoDto();
  etapaAtual: number = 1;
  exibirSalario: boolean = false;
  exibirBeneficio: boolean = false;
  candidatoDto : CandidatoDto;
  private candidatoSub: Subscription = new Subscription();
  saving = false;


  constructor(
    private _onboardingService: OnboardingCandidatoServiceProxy,
    public candidatoService: CandidatoService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.initCandidato();
  }

  handleInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  convertAndAssignValue(inputElement: HTMLInputElement): void {
    let value = inputElement.value;
    value = value.replace(/\./g, '').replace(',', '.');
    const parsedValue = parseFloat(value);
  }


  initCandidato(): void {
    this.candidatoDto = this.candidatoService.getCandidato();
    this.candidatoSub = this.candidatoService.getCandidatoUpdateListener()
      .subscribe((candidato: CandidatoDto) => {
        this.candidatoDto = candidato;
      });
  }

  proximaEtapa(): void {
    this.etapaAtual = 2;
  }

  exibirCampoSalario(exibir: boolean): void {
    this.exibirSalario = exibir;
  }

  exibirCampoBeneficio(exibir: boolean): void {
    this.exibirBeneficio = exibir;
  }

  createOnboardingCandidato(): void {
    this.saving = true;
    if(!this.verificarInputs())
    {
      this.saving  = false;
      return;
    }

    abp.ui.setBusy();
    this.onboarding.candidatoId = this.idCandidato;
    this._onboardingService.create(this.onboarding).pipe(
      finalize(() => {
        abp.ui.clearBusy();
        this.saving = false;
      })
    ).subscribe((x) => {
      this.candidatoDto.onboardingCandidato = x;
      this.candidatoService.setCandidato(this.candidatoDto);
      abp.notify.success("Formulário preenchido!");
      this.bsModalRef.hide();
    });
  }

  verificarInputs(){
    if(this.onboarding.temSalarioOuRenda == null){
      abp.notify.error("O campo "+ "'Possui salário/renda atualmente?'" + " é obrigátorio!");
      return false;
    }
    if(this.onboarding.temSalarioOuRenda && !this.onboarding.valorSalario){
      abp.notify.error("O campo valor salário/renda atualmente é obrigátorio!");
      return false;
    }
    if(!this.onboarding.valorRendaFamiliar){
      abp.notify.error("O campo valor renda familiar é obrigátorio!");
      return false;
    }
    if(this.onboarding.temBeneficioGoverno == null){
      abp.notify.error("O campo "+ "'Você ou sua família possui Benefícios Proveniente do governo?'" + " é obrigátorio!");
      return false;
    }
    if(this.onboarding.temBeneficioGoverno && !this.onboarding.valorBeneficio){
      abp.notify.error("O campo valor benefício é obrigátorio!");
      return false;
    }
    return true
  }
}
