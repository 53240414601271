import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CandidatoDto } from '@shared/service-proxies/service-proxies';



@Injectable({ providedIn: 'root' })

export class CandidatoService {

    private candidato: CandidatoDto;
    private candidatoUpdated = new Subject<CandidatoDto>();
    private readonly STORAGE_KEY = 'inscrito';

    getCandidato() {
        return this.candidato;
    }

    getCandidatoUpdateListener() {
        return this.candidatoUpdated.asObservable();
    }

    setCandidato(candidato: CandidatoDto) {
        this.candidato = candidato;
        this.candidatoUpdated.next(this.candidato);
    }

    setInscrito(value: boolean) {
        //Armazena no navegador se o candidato está inscrito ou não na vaga
        //para caso ele atualize a página não perca nenhuma informação
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(value));
    }

    getInscrito() {
        //Pega da mémoria do navegador a informação se o usuário está inscrito ou não
        const value = localStorage.getItem(this.STORAGE_KEY);
        return value ? JSON.parse(value) : false;
    }
}
