import { Injectable } from "@angular/core";
import { PermissionCheckerService } from "abp-ng2-module";
import { AppSessionService } from "../session/app-session.service";

import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import {
  TermosServiceProxy,
  TipoTermoEnum,
} from "@shared/service-proxies/service-proxies";
import { BsModalService } from "ngx-bootstrap/modal";
import { AcceptTermoDialogComponent } from "@shared/components/accept-termo-dialog/accept-termo-dialog.component";

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _permissionChecker: PermissionCheckerService,
    private _router: Router,
    private _sessionService: AppSessionService,
    private _termosService: TermosServiceProxy,
    private _modalService: BsModalService
  ) {}
  canLoad(route: Route, segments: UrlSegment[]): boolean {

    const hasCandidatoAndDetalhesVaga = segments.some(x => x.path === "candidato") && segments.some(x => x.path === "detalhes-vaga");
    const hasCandidatoAndHome = segments.some(x => x.path === "candidato") && segments.some(x => x.path === "home");
    if(!this._sessionService.user && hasCandidatoAndDetalhesVaga){
      this._router.navigate(["/vagas/detalhes-vaga/", segments[2].path]);
      return false;
    }
    else  if(!this._sessionService.user && hasCandidatoAndHome){
      this._router.navigate(["/vagas"]);
      return false;
    }
    else if (!this._sessionService.user) {
      this._router.navigate(["/"]);
      return false;
    }

    if(segments.findIndex(v => v.path.includes('termos') || v.path.includes('politica')) == -1) {
      this.verifyTermosAcceptance();
    }

    if (!route.data || !route.data["role"]) {
      return true;
    }

    if (this._sessionService.userHasRole(route.data["role"])) {
      return true;
    }

    this._router.navigate(["/"]);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this._sessionService.user) {
      this._router.navigate(["/"]);
      return false;
    }

    if (!route.data || !route.data["permission"]) {
      return true;
    }

    if (this._permissionChecker.isGranted(route.data["permission"])) {
      return true;
    }

    this._router.navigate([this.selectBestRoute()]);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  selectBestRoute(): string {
    if (!this._sessionService.user) {
      return "/";
    }

    if (this._permissionChecker.isGranted("Pages.Users")) {
      return "/administrativo/users";
    }

    return "/administrativo/home";
  }

  verifyTermosAcceptance(): void {
    this._termosService
      .verifyAcceptance()
      .subscribe(
        (result) => {
          const pending: Array<TipoTermoEnum> = [];

          if(!(result['TERMOS_DE_USO'] ?? true)) pending.push(TipoTermoEnum._1);
          if(!(result['POLITICA_PRIVACIDADE'] ?? true)) pending.push(TipoTermoEnum._0);

          if(pending.length) this.openAcceptTermoModal(pending);
        },
        (error) => {}
      );
  }

  openAcceptTermoModal(types: TipoTermoEnum[]): void {
    this._modalService.show(AcceptTermoDialogComponent, {
      class: "modal-dialog-centered",
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        types,
      },
    });
  }
}
