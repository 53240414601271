import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { EnumDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'select-box-custom',
  templateUrl: './select-box-custom.component.html',
  styleUrls: ['./select-box-custom.component.scss']
})
export class SelectBoxCustomComponent extends AppComponentBase {
  @Output() selectedOptionChange = new EventEmitter<number>();
  @Input() selectedOption: number = -1;
  @Input() listaModeloTrabalho: EnumDto[] = [];

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  onOptionChange(option: number) {
    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
    this.cdr.detectChanges();
  }

  getDisplayName(name: string | undefined): string {
    if (name === 'Home Office') {
      return 'Remoto';
    }
    return name || '';
  }

  limparCheckBox(){
    this.selectedOption = -1;
    this.cdr.detectChanges();
  }
}
