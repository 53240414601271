import { Component, Injector, OnInit } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import { GoogleAnalyticsAcoes } from "@shared/constant/google-analytics-acoes.model";
import { Pages } from "@shared/constant/peges.model";
import {
  TermosServiceProxy,
  TipoTermoEnum,
} from "@shared/service-proxies/service-proxies";
import { GoogleAnalyticsService } from "@shared/services/google-analytics.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-terms-of-use",
  templateUrl: "./terms-of-use.component.html",
  styleUrls: ["./terms-of-use.component.scss"],
  animations: [appModuleAnimation()],
})
export class TermsOfUseComponent extends AppComponentBase implements OnInit {
  public termsOfUse: string;
  constructor(injector: Injector, private _termosService: TermosServiceProxy, private _googleAnalyticsService: GoogleAnalyticsService) {
    super(injector);
  }

  ngOnInit(): void {
    this.termsOfUse = '';
    this.getTermsOfUse();
    this.googleAnalyticsEventSend();
  }

  googleAnalyticsEventSend() {
    this._googleAnalyticsService.sendEmiter(GoogleAnalyticsAcoes.acessoTermoDeUso, {
      event_label: Pages.termoDeUso
    });
  }

  getTermsOfUse() {
    abp.ui.setBusy();
    this._termosService
      .getLatestTermo(TipoTermoEnum._1)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((termo) => {
        this.termsOfUse = termo.texto || "";
      });
  }
}
