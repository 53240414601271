<div class="toggle-wrapper">
  <div class="toggle-container" (click)="toggle()">
    <div class="toggle-switch" [class.active]="value">
      <div class="toggle-ball"></div>
    </div>
  </div>
  <div class="toggle-label">
    {{ value ? 'Sim' : 'Não' }}
  </div>
</div>
