<div class="modal-content">
    <div class="modal-dialog rounded">
        <!-- Etapa 1 -->
        <div class="modal-body text-center" *ngIf="etapaAtual === 1">
            <img src="../../../assets/img/para_candidatos/onboardingImg.svg" class="mb-10" alt="Imagem Onboarding">
            <h5 class="modal-title mb-3">Seja bem-vindo(a) ao Conecta! Agora você faz parte da nossa comunidade.</h5>

            <div class="card-plataforma p-3 mb-4 mt-10 rounded">
                <p><span class="step-description">Personalize seu perfil:</span> Crie um perfil completo para receber as
                    melhores vagas.</p>
                <p><span class="step-description">Explore as vagas:</span> Encontre as oportunidades que mais combinam
                    com você.</p>
            </div>

            <p class="card-plataforma-text"><span class="step-description">Comece agora sua jornada rumo a um novo
                    desafio!</span></p>

            <button type="button" class="btn btn-green w-100 mt-6" (click)="proximaEtapa()">
                Personalizar perfil
            </button>
        </div>


        <div class="modal-body" *ngIf="etapaAtual === 2">
            <h5 class="modal-title-secondary mb-3">Personalizar perfil</h5>

            <form #form="ngForm" autocomplete="off" (ngSubmit)="createOnboardingCandidato()">
                <p class="step-description-secondary mt-6">
                    Personalize seu perfil para receber as melhores vagas! Quanto mais completo, mais precisas serão as
                    nossas sugestões.
                    <span class="step-description-span">Seus dados são protegidos e não serão compartilhados.</span>
                </p>


                <div class="form-group">
                    <label class="form-label required">Possui salário/renda atualmente?</label>
                    <mat-radio-group name="temSalarioOuRenda" [(ngModel)]="onboarding.temSalarioOuRenda" required
                        class="d-flex flex-column">
                        <mat-radio-button [value]="false">Não possuo renda atualmente</mat-radio-button>
                        <mat-radio-button [value]="true">Sim</mat-radio-button>
                    </mat-radio-group>
                </div>


                <div class="form-group col-md-12 mx-auto" *ngIf="onboarding.temSalarioOuRenda">
                    <label class="form-label required">Qual o valor do salário/renda atualmente?</label>
                    <input required class="form-control" mask="separator.2" prefix="R$ " thousandSeparator="."
                        decimalMarker="," maxlength="12" [(ngModel)]="onboarding.valorSalario" name="valorSalario"
                        (keydown)="handleInput($event)" (input)="convertAndAssignValue($event.target)" />
                </div>


                <div class="form-group">
                    <label class="form-label required">Qual a renda da sua família? <span class="form-group-span">(é a
                            soma da renda de todos os membros da família, incluindo a sua própria renda).</span></label>
                    <input mask="separator.2" prefix="R$ " thousandSeparator="." decimalMarker="," maxlength="12" required
                        class="form-control" [(ngModel)]="onboarding.valorRendaFamiliar" name="valorRendaFamiliar"
                        (keydown)="handleInput($event)" (input)="convertAndAssignValue($event.target)" />
                </div>

                <div class="form-group">
                    <label class="form-label required">Você ou sua família possui Benefícios Proveniente do governo?
                        <span class="form-group-span">(Qualquer recurso financeiro que é transferido diretamente do
                            governo para o cidadão que participa de programas sociais específicos).</span></label>
                    <mat-radio-group name="temBeneficioGoverno" [(ngModel)]="onboarding.temBeneficioGoverno" required
                        class="d-flex flex-column">
                        <mat-radio-button [value]="false">Não</mat-radio-button>
                        <mat-radio-button [value]="true">Sim</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="form-group col-md-12 mx-auto" *ngIf="onboarding.temBeneficioGoverno">
                    <label class="form-label required">Qual o valor que você recebe desses benefícios?</label>
                    <input required class="form-control" mask="separator.2" prefix="R$ " thousandSeparator="."
                        decimalMarker="," maxlength="12" [(ngModel)]="onboarding.valorBeneficio" name="valorBeneficio"
                        (keydown)="handleInput($event)" (input)="convertAndAssignValue($event.target)" />
                </div>

                <div class="row">
                    <div class="col-md-6 offset-md-6">
                        <button type="submit" [disabled]="saving" class="btn btn-green w-100">Salvar informações</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

