<div class="card-anuncio mb-6 p-5">
    <div class="card-completo">
        <div class="row mt-3 px-5 linha-informacoes-perfil">
            <div class="square-image">
                <img *ngIf="!vaga.confidencial" class="perfil-image"
                    [src]="vaga.empresa?.urlFotoPerfilDesbloqueado != undefined ? vaga.empresa?.urlFotoPerfilDesbloqueado : '../../assets/img/user-void.png'"
                    alt="perfil-image" />
                <img *ngIf="vaga.confidencial" class="perfil-image" src="../../assets/img/vaga.png"
                    alt="perfil-image" />
            </div>

            <div class="square-informacoes">
                <div>
                    <div *ngIf="!vaga.confidencial">
                        <span class="vaga-subtitulo vaga-linha-1">{{ vaga.empresa.nome }}</span>
                    </div>
                    <div *ngIf="vaga.confidencial">
                        <span class="vaga-subtitulo vaga-linha-1">Confidencial</span>
                    </div>
                </div>

                <div>
                    <span class="vaga-titulo vaga-linha-2">
                        {{ vaga.cargo.nome }}
                    </span>
                </div>

                <div class="align-items-center">
                    <span class="vaga-cidade vaga-linha-1">
                        {{ vaga.cidade }} - {{ vaga.estado }}
                    </span>
                </div>
            </div>

        </div>


        <mat-divider id="divider-web" class="pl-2"></mat-divider>

        <mat-divider id="divider-app"></mat-divider>

        <div class="row align-items-start mt-5 px-5 conteudos">

            <div class="col-12 descricao-caixa">
                <div class="descricao-limite" [innerHTML]="vaga.descricao"></div>
            </div>

            <div class="col-12 caixa-info-2 mb-3">
                <div class="row">
                    <div *ngIf="vaga.competencias && vaga.competencias.length > 0" class="col-6">
                        <div class="row mb-2">
                            <span class="detalhes-textos-titulo">Competências: </span>
                        </div>
                        <ul class="detalhes-textos">
                            <ng-container *ngFor="let competencia of vaga.competencias; let i = index">
                                <li *ngIf="i < 2">
                                    <span class="competencia-clamp">{{ competencia.nome }}</span>
                                </li>
                            </ng-container>
                            <li *ngIf="vaga.competencias.length > 2">e mais {{ vaga.competencias.length - 2 }}...</li>
                        </ul>
                    </div>
                
                    <div [ngClass]="vaga.competencias && vaga.competencias.length > 0 ? 'col-6' : 'col-12'">
                        <div class="row mb-2">
                            <span class="detalhes-textos-titulo">Regime de trabalho: </span>
                        </div>
                        <ul class="detalhes-textos">
                            <li>{{ vaga.horasSemanais }} Horas Semanais</li>
                            <li><span class="no-wrap">{{ vaga.modeloTrabalhoString }}</span></li>
                            <li><span class="no-wrap">{{ vaga.regimeTrabalhoString }}</span></li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div class="col-12 caixa-info">
                <div class="row mb-3">
                    <div class="vaga-info">
                        <div class="d-flex align-items-center justify-content-center modelo-trabalho-titulo">
                            <img *ngIf="vaga.modeloTrabalhoString == modeloPresencial" class="img-icons"
                                src="../../assets/img/vitrine-vagas/presencial.svg" alt="Modelo">
                            <img *ngIf="vaga.modeloTrabalhoString == modeloHomeOffice" class="img-icons"
                                src="../../assets/img/vitrine-vagas/remoto.svg" alt="Modelo">
                            <span class="ms-2">
                                {{ vaga.modeloTrabalhoString === modeloHomeOffice ? 'Remoto' : vaga.modeloTrabalhoString
                                }}
                            </span>
                        </div>
                    </div>

                    <div *ngIf="(vaga.salario && vaga.exibirSalario == true) || (vaga.salario && (vaga.exibirSalario == null || vaga.exibirSalario == undefined))" class="col-5 col-md-5 col-lg-4 vaga-info">
                        <div class="d-flex align-items-center justify-content-center modelo-trabalho-titulo">
                            <span>{{ vaga.salario | currency:'BRL':'symbol':'1.2-2' }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="vaga.pcd && vaga.pcd == true" class="row">
                    <div class="vaga-info vaga-info-pcd">
                        <div class="d-flex align-items-center justify-content-center modelo-trabalho-titulo">
                            <img class="img-icons" src="../../assets/img/vitrine-vagas/pcd-icon.svg" alt="Modelo">
                            <span class="ms-2">Vaga exclusiva para PcD</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex align-items-center">
            <div class="col-botao-detalhes" (click)="detalhes()">
                <a href="javascript:;" class="detalhes-link">Ver Detalhes</a>
            </div>
            <div class="col-botao-candidatar">
                <input type="button" value="Quero me candidatar" class="btn btn-green w-100 botao-quero-candidatar"
                    (click)="candidatar()">
            </div>
        </div>

    </div>
</div>