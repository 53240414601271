import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CidadeMicrorregiaoDto, CidadeMicrorregiaoServiceProxy, EnderecoDto } from "@shared/service-proxies/service-proxies";
import {
  ConsultaCEPResponse,
  ConsultaCepService,
} from "@shared/services/consulta-cep.service";
import { finalize, Observable, Subscription, throwError } from "rxjs";
import { JsonDataService } from "@shared/services/json-data.service";
import { DropdownSettings } from "@shared/constant/dropdown-settings.model";

@Component({
  selector: "endereco-form",
  templateUrl: "./endereco-form.component.html",
})
export class EnderecoFormComponent extends AppComponentBase {
  @Input() oldEndereco: EnderecoDto;
  @Input() perfil: boolean = false;
  @Output() fieldChange = new EventEmitter<EnderecoDto>();

  endereco: EnderecoDto = new EnderecoDto();
  subscriptions: Subscription[] = [];
  dropdownSettings = DropdownSettings.getSettings(true);
  selectedEstados = [];
  selectedCidades = [];
  dropdownEstadosList = [];
  dropdownCidadesList = [];
  desabilitarCidade = true;
  listaCidadesMicrorregioes: CidadeMicrorregiaoDto[]

  constructor(
    injector: Injector,
    private _cidadesMicrorregioesService: CidadeMicrorregiaoServiceProxy,
    private _buscarCepService: ConsultaCepService,
    private cdr: ChangeDetectorRef,
    private dataService: JsonDataService,
  ) {
    super(injector);
    registerLocaleData(localePtBr);
  }

  ngOnInit() {
    this.loadEstados();
  }

  onCepBlur() {
    if (this.endereco.cep.length < 8 && this.endereco.cep.length > 0) {
      abp.notify.warn("CEP deve ter 8 dígitos.");
      if (this.selectedCidades.length > 0 || this.selectedEstados.length > 0) {
        this.onCidadeDeSelected();
        this.onEstadoDeSelected();
      }
    }
    else if (this.endereco.cep == "") {
      this.onCidadeDeSelected();
      this.onEstadoDeSelected();
    }
    else {
      this.onSelectCep(this.endereco.cep);
    }
  }

  onSelectCep(cep: string) {
    if (!this.endereco.cep) {
      return true;
    }
    cep = cep.replace(/\D/g, "").trim();
    const sub = this.consultarCep(cep).subscribe(
      (response) => {
        if (response.hasOwnProperty("erro")) {
          this.onCidadeDeSelected();
          this.onEstadoDeSelected();
          abp.message.error("CEP inválido");
          return false;
        } else {
          this.buscarCidadeIBGE(response.ibge);
          this.endereco.logradouro =
            this.endereco.logradouro || response.logradouro;
          this.endereco.bairro = this.endereco.bairro || response.bairro;
          this.cdr.markForCheck();
        }
      }
    );
    this.subscriptions.push(sub);
  }

  buscarCidadeIBGE(ibge) {
    this._cidadesMicrorregioesService.getCidadeIBGE(ibge)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((cidadeMicrorregiao: CidadeMicrorregiaoDto) => {
        this.endereco.estado = cidadeMicrorregiao.estado;
        this.endereco.microrregiaoId = cidadeMicrorregiao.microrregiaoId;
        this.endereco.cidade = cidadeMicrorregiao.cidade;

        this.selectedEstados = [{
          'item_text': this.dataService.getEstadoNome(cidadeMicrorregiao.estado),
          'item_id': cidadeMicrorregiao.estado,
        }]
        this.loadCidades(cidadeMicrorregiao.estado);
        this.selectedCidades = [{
          'item_text': cidadeMicrorregiao.cidade,
          'item_id': cidadeMicrorregiao.ibgeId,
        }]
      });
  }

  consultarCep(cep: string): Observable<ConsultaCEPResponse> {
    cep = cep.replace(/\D/g, "");
    if (cep !== " ") {
      return this._buscarCepService.consultaCEP(cep);
    } else {
      this.onCidadeDeSelected();
      this.onEstadoDeSelected();
      return throwError("CEP inválido");
    }
  }

  loadEstados() {
    this.dataService.getEstados().subscribe(estados => {
      this.dropdownEstadosList = estados.map((estado: any) => ({
        item_id: estado.Sigla,
        item_text: estado.Nome,
      }));

      if (this.oldEndereco.estado) {
        this.endereco = this.oldEndereco;

        if (this.endereco.estado === "Desconhecido") {
          this.dropdownEstadosList.push({
            'item_text': "Desconhecido",
            'item_id': "Desconhecido",
          });
        }

        const estadoNome = this.endereco.estado === "Desconhecido"
          ? "Desconhecido"
          : this.dataService.getEstadoNome(this.endereco.estado);

        this.selectedEstados = [{
          'item_text': estadoNome,
          'item_id': this.endereco.estado,
        }];

        this.cdr.markForCheck();
        this.loadCidades(this.endereco.estado, true);
      }
    });
  }

  onEstadoSelected(item: any) {
    abp.ui.setBusy();
    this.endereco.estado = item['item_id'];
    this.endereco.cidade = undefined;
    this.selectedCidades = undefined;
    this.loadCidades(item['item_id']);
    this.desabilitarCidade = false;
    this.onFieldChangeEndereco();
  }

  onEstadoDeSelected() {
    this.desabilitarCidade = true;
    this.selectedCidades = undefined;
    this.selectedEstados = undefined;
    this.endereco.cidade = undefined;
    this.listaCidadesMicrorregioes = undefined;
    this.onFieldChangeEndereco();
  }


  loadCidades(estado, edit = false) {
    this._cidadesMicrorregioesService.getCidadesEstado(estado)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((cidadesMicrorregioes: CidadeMicrorregiaoDto[]) => {
        this.listaCidadesMicrorregioes = cidadesMicrorregioes;
        this.dropdownCidadesList = cidadesMicrorregioes.map((cidadeMicrorregiao: CidadeMicrorregiaoDto) => ({
          item_id: cidadeMicrorregiao.ibgeId,
          item_text: cidadeMicrorregiao.cidade,
        }));
        if (estado == "Desconhecido") {
          this.desabilitarCidade = true;
        }
        if (edit) {
          this.desabilitarCidade = estado != "Desconhecido" ? false : true;
          const cidade = this.dropdownCidadesList.find((item) => item['item_text'] === this.endereco.cidade);

          if (cidade) {
            this.selectedCidades = [{
              'item_text': cidade['item_text'],
              'item_id': cidade['item_id'],
            }];
          }
        }
        abp.ui.clearBusy();
      });
  }

  onCidadeSelected(item: any) {
    this.endereco.cidade = item['item_text'];
    this.endereco.microrregiaoId = this.listaCidadesMicrorregioes.find((cidadeMicrorregiao) => cidadeMicrorregiao.ibgeId == item['item_id']).microrregiaoId;
    this.onFieldChangeEndereco();
  }

  onCidadeDeSelected() {
    this.endereco.cidade = undefined;
    this.endereco.microrregiaoId = undefined;
    this.onFieldChangeEndereco();
  }

  updateEndereco(): EnderecoDto {
    return this.endereco;
  }

  onFieldChangeEndereco() {
    this.fieldChange.emit();
  }

  validarCidadeEstadoCep(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.endereco.cep && this.endereco.cep.length > 0) {
        const cep = this.endereco.cep.replace(/\D/g, "").trim();

        if (cep.length < 8 && cep.length > 0) {
          resolve(false);
          return;
        }

        this.consultarCep(cep).subscribe(
          (response: ConsultaCEPResponse) => {
            if (response.hasOwnProperty("erro")) {
              resolve(false);
              return;
            }

            const cidadeCep = response.localidade;
            const estadoCep = response.uf;

            if (
              (this.endereco.estado && this.endereco.estado !== estadoCep) ||
              (this.endereco.cidade && this.endereco.cidade !== cidadeCep)
            ) {
              this.resetCidadeEstado();
              this.endereco.estado = null;
              this.endereco.cidade = null;
              resolve(false);
              return;
            }

            this.endereco.estado = estadoCep;
            this.endereco.cidade = cidadeCep;
            this.endereco.logradouro = this.endereco.logradouro || response.logradouro;
            this.endereco.bairro = this.endereco.bairro || response.bairro;

            this.cdr.markForCheck();
            resolve(true);
            return;
          },
          (error) => {
            resolve(false);
            return;
          }
        );
      } else {
        resolve(true);
        return;
      }
    });
  }


  resetCidadeEstado() {
    this.selectedEstados = [];
    this.selectedCidades = [];
    this.cdr.markForCheck();
  }
}