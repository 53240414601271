import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

export interface ConsultaCEPResponse {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro?: string;
  siafi: string;
  uf: string;
}

@Injectable({
  providedIn: "root",
})
export class ConsultaCepService {
  constructor(private http: HttpClient) {}

  public consultaCEP(cep: string): Observable<ConsultaCEPResponse> {
    cep = cep.replace(/\D/g, "");

    if (cep !== "") {
      const validaCep = /^[0-9]{8}$/;

      if (validaCep.test(cep)) {
        return new Observable<ConsultaCEPResponse>((observer) => {
          var request = new XMLHttpRequest();
          request.open("GET", `https://viacep.com.br/ws/${cep}/json`, true);
          request.onreadystatechange = () => {
            if (request.readyState === 4) {
              if (request.status === 200) {
                const data = JSON.parse(request.responseText);
                observer.next(data);
                observer.complete();
              } else {
                observer.error("Erro na consulta do CEP");
              }
            }
          };
          request.send();
        });
      }
    }

    return throwError("CEP inválido");
  }
}
