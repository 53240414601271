export enum EnumNames {
    REGIME_TRABALHO_ENUM = "RegimeTrabalho",
    MODELO_TRABALHO_ENUM = "ModeloTrabalho",
    NIVEL_COMPETENCIA_ENUM = "NivelCompetencia",
    NIVEL_IDIOMA_ENUM = "NivelIdioma",
    RENDA_BRUTA_FAMILIAR_ENUM = "RendaBrutaFamiliarEnum",
    TIPO_FILTRO_GRAFICO_TOTAL_CADASTRO_ENUM = "TipoFiltroGraficoTotalCadastro",
    TIPO_RELATORIO_ENUM = "TipoRelatorio",
    ESTAGIO_TRIAGEM = "EstagioTriagem",
    AREA_ATUACAO = "AreaAtuacaoEmpresaEnum"
}
