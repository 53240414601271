import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'abp-pagination-custom-controls',
  templateUrl: './abp-pagination-controls.component.html',
  styleUrls: ["./abp-pagination-custom-controls.component.scss"]
})
export class AbpPaginationCustomControlsComponent {

  @Input() id: string;
  @Input() maxSize = 7;
  @Input() previousLabel = 'Previous';
  @Input() nextLabel = 'Next';
  @Input() screenReaderPaginationLabel = 'Pagination';
  @Input() screenReaderPageLabel = 'page';
  @Input() screenReaderCurrentLabel = `You're on page`;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  currentPage: number = 1;

  private _directionLinks = true;
  private _autoHide = false;

  @Input()
  get directionLinks(): boolean {
    return this._directionLinks;
  }
  set directionLinks(value: boolean) {
    this._directionLinks = !!value && <any>value !== 'false';
  }
  @Input()
  get autoHide(): boolean {
    return this._autoHide;
  }
  set autoHide(value: boolean) {
    this._autoHide = !!value && <any>value !== 'false';
  }
  
  setCurrentPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.pageChange.emit(this.currentPage);
  }

  proximaPagina(paginationApi: any) {
    const totalPages = paginationApi.pages.length;
    if (this.currentPage < totalPages) {
      this.currentPage++;
      this.setCurrentPage(this.currentPage);
      paginationApi.setCurrent(this.currentPage);
    }
  }
}
