<div class="input-select-container mb-5">
  <div class="input-group">
    <div class="input-with-arrow">

      <input type="text" class="form-control" [placeholder]="placeholder" [(ngModel)]="selectValor.item_text "
        (click)="toggleDropdown()" (input)="onInput($event)" (keyup.enter)="onEnterKey()" [disabled]="disabled" />
    </div>
  </div>

  <ul class="dropdown-list" *ngIf="isDropdownVisible">

    <ng-container *ngIf="filteredValores?.length > 0; else noResults">
      <li class="li-content" *ngFor="let valor of filteredValores" (click)="selectValorFromList(valor) ">
        {{ valor.item_text }}
      </li>
    </ng-container>

    <ng-template *ngIf="inputLivre == false" #noResults>
      <li class="no-results">Nenhum resultado</li>
    </ng-template>
  </ul>
</div>
