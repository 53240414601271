import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CandidatoDto, VagaDto } from "@shared/service-proxies/service-proxies";
import { Router } from "@angular/router";
import { CandidatoService } from "candidato/service/candidato.service";
import { ConfirmationDialogComponent } from "./detalhes/candidatar/candidatar-vaga.component";
import { MatDialog } from "@angular/material/dialog";
import { ModelosTrabalho } from 'shared/constant/modelos-trabalho.model';

@Component({
  selector: "card-candidato-vaga",
  templateUrl: "./card-candidato-vaga.component.html",
  styleUrls: ["./card-candidato-vaga.component.scss"],
})
export class CardCandidatoVagaComponent extends AppComponentBase implements OnInit {
  @Input() vaga: VagaDto;
  @Input() isInscrito: boolean;
  pcdTitle: string = this.l("Vaga para pessoas com deficiência.");
  candidato: CandidatoDto = new CandidatoDto();
  modeloHomeOffice: ModelosTrabalho = ModelosTrabalho.homeoffice;
  modeloPresencial: ModelosTrabalho = ModelosTrabalho.presencial;
  modeloHibrido: ModelosTrabalho = ModelosTrabalho.hibrido;


  constructor(
    injector: Injector,
    private _router: Router,
    private candidatoService: CandidatoService,
    private dialog: MatDialog,
  ) {
    super(injector);
    registerLocaleData(localePtBr);
  }
  ngOnInit(): void {
  }


  detalhes() {
    this.candidatoService.setInscrito(this.isInscrito);
    this._router.navigate(['candidato', 'detalhes-vaga', this.vaga.id]);
  }
  
  candidatar() {
    this.candidato = this.candidatoService.getCandidato();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        vaga: this.vaga,
        candidato: this.candidato,
      },
    });
  }

}
