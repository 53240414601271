import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';


import { AbpHttpInterceptor } from 'abp-ng2-module';

import { SharedModule } from '@shared/shared.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { RootRoutingModule } from './root-routing.module';
import { AppConsts } from '@shared/AppConsts';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

import { RootComponent } from './root.component';
import { AppInitializer } from './app-initializer';

import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './environments/environment';
import { PixelModule } from "ngx-multi-pixel";

export function getCurrentLanguage(): string {
	if (abp.localization.currentLanguage.name) {
		return abp.localization.currentLanguage.name;
	}

	// todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
	return 'pt-BR';
}

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
//import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InlineSVGModule } from 'ng-inline-svg-2';
// #fake-end#

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		SharedModule.forRoot(),
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		TabsModule.forRoot(),
		ServiceProxyModule,
		RootRoutingModule,
		//SplashScreenModule,
		TranslateModule.forRoot(),
		HighlightModule,
		ClipboardModule,
		// #fake-start#
		environment.isMockEnabled
			? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
				passThruUnknownUrl: true,
				dataEncapsulation: false,
			})
			: [],
		// #fake-end#
		//AppRoutingModule,
		NgbModule,
		//Metronic
		InlineSVGModule.forRoot(),
		PixelModule.forRoot({ enabled: true, pixelId: ["810618557769075"] }),
	],
	declarations: [RootComponent],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
			deps: [AppInitializer],
			multi: true,
		},
		{ provide: API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					xml: () => import('highlight.js/lib/languages/xml'),
					typescript: () => import('highlight.js/lib/languages/typescript'),
					scss: () => import('highlight.js/lib/languages/scss'),
					json: () => import('highlight.js/lib/languages/json')
				},
			},
		},
	],
	bootstrap: [RootComponent],
})
export class RootModule { }
