<div class="main" *ngIf="experiencias && experiencias.length > 0">
    <h3 class="heading">Histórico de experiência</h3>

    <div class="container">

        <ul>
            <li *ngFor="let experiencia of experiencias">
                <div *ngIf="exibirLixo" style=" text-align: right;">
                    <div>
                        <span (click)="delete(experiencia)"
                            class="btn btn-icon btn-circle  w-25px h-25px bg-body shadow">
                            <i class="fas fa-trash icone"><span class="path1"></span><span class="path2"></span></i>
                        </span>
                    </div>
                </div>
                <h3 class="title">{{experiencia.titulo}}</h3>
                <p> {{experiencia.dataFim ? "Trabalhou" : "Trabalha"}} na <strong>{{experiencia.nomeEmpresa}}</strong> localizada em
                    <strong>{{experiencia.cidade}} - {{experiencia.uf}}</strong></p>
                <div>{{experiencia.descricao}} </div>

                <p *ngIf="experiencia.tipoEmprego != '' && experiencia.regimeTrabalho != ''">{{getTipoTrabalho(experiencia.tipoEmprego)}} - {{getModeloTrabalho( experiencia.regimeTrabalho)}}</p>
                <p *ngIf="!experiencia.dataFim || experiencia.dataFim == undefined">Teve
                    início no cargo {{experiencia.titulo}} na data
                    {{experiencia.dataInicio | date:
                    'dd/MM/yyyy'}} e segue até a data atual</p>

                <span class="circle"></span>
                <span *ngIf="experiencia.dataFim" class="date">Início: {{experiencia.dataInicio | date: 'dd/MM/yyyy'}}
                    - Térm: {{experiencia.dataFim | date: 'dd/MM/yyyy'}}</span>
            </li>

        </ul>

    </div>
</div>
