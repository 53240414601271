import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public sendEmiter(
    eventName: string,
    eventParams: { [key: string]: any } = {}
  ) {
    gtag('event', eventName, eventParams);
  }

  public trackPageView(url: string) {
    gtag('config', 'G-0SNN8ED67M', {
      'page_path': url
    });
  }

}
