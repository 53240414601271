import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { FiltroService } from "@shared/components/filtro/service/filtro.service";
import { SelectItem } from "../filtro/select-item.interface";
import { EnumDto } from "@shared/service-proxies/service-proxies";

@Component({
  selector: "app-modal-filtro",
  templateUrl: "./modal-filtro.dialog.component.html",
  styleUrls: ["./modal-filtro.dialog.component.scss"],
})
export class FiltroDialogComponent extends AppComponentBase implements OnInit {
  public submitting = false;
  @Input() empresas: SelectItem[] = [];
  @Input() listaModeloTrabalho: EnumDto[] = [];
  @Input() max: number = 0;
  @Input() isLoggedIn: boolean = false;
  
  constructor(
    public injector: Injector,
    public bsModalRef: BsModalRef,
    public authService: AppAuthService,
    public filtroService: FiltroService,

  ) {
    super(injector);
  }

  ngOnInit(): void { }

  close(){
    this.bsModalRef.hide();
  }
}
