<div class="input-group position-relative">
  <input
    type="text"
    [placeholder]="placeholder"
    [formControl]="control"
    [matAutocomplete]="auto"
    class="form-control"
    (input)="onOptionTyped($event.target.value)"
  />
</div>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelected($event)">
  <mat-option
    *ngFor="let option of filteredOptions | async"
    [value]="option"
  >
    {{ getOptionLabel(option) }}
  </mat-option>
</mat-autocomplete>