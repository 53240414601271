<div *ngIf="(show == true)"
    class="row pb-10 px-5">
    <ngb-toast class="col-12 w-100 px-5 py-8" [autohide]="false" (hidden)="show = false">
        <ng-template ngbToastHeader>
            <div class="me-auto">
                <i class="fa-sharp fa-solid fa-triangle-exclamation text-orange mr-3"></i>
                <span class="mx-1 title pr-2">{{title}}</span>
            </div>
        </ng-template>
        <p class="subtitle">
           {{text}}
        </p>
        <div *ngIf="showButton">
            <input type="button" class="btn btn-primary float-right mt-4" [value]="page" (click)="goToPage()">
        </div>
    </ngb-toast>
</div>