import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent implements OnInit {

  show: boolean = true
  @Input() title: string;
  @Input() text: string;
  @Input() page: string;
  @Input() rota: string;
  @Input() showButton: boolean;
  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  goToPage(){
    this._router.navigate(["candidato", this.rota]);
  }

}
