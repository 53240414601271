import { Injectable } from "@angular/core";
import { AppSessionService } from "../session/app-session.service";

import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";

@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _router: Router,
    private _sessionService: AppSessionService
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (!!this._sessionService.user) {
      this._router.navigate([this.selectBestRoute()]);
      return false;
    }

    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!!this._sessionService.user) {
      this._router.navigate([this.selectBestRoute()]);
      return false;
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  selectBestRoute(): string {
    const role = this._sessionService.getRoles()[0];
    switch (role) {
      case "Candidato":
        return "/candidato";
      case "Empresa":
        return "/empresa";
      case "Admin":
        return "/administrativo";
      default:
        return "/administrativo";
    }
  }
}
