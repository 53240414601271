import { Component, Injector, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import {
  TermosServiceProxy,
  TipoTermoEnum,
} from "@shared/service-proxies/service-proxies";
import { BsModalRef } from "ngx-bootstrap/modal";
import { PixelService } from "ngx-multi-pixel";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-accept-termo-dialog",
  templateUrl: "./accept-termo-dialog.component.html",
  styleUrls: ["./accept-termo-dialog.component.scss"],
})
export class AcceptTermoDialogComponent
  extends AppComponentBase
  implements OnInit
{
  public types: TipoTermoEnum[];
  public routePrefix: string = "";

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private _termosService: TermosServiceProxy,
    private pixel: PixelService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getRoutePrefix();
    this.onConsent();
  }

  getRoutePrefix() {
    if (!this.appSession.user) return;
    if (this.appSession.getRoles().length) {
      const role = this.appSession.getRoles()[0];
      
      switch(role){
        case 'Candidato':
          this.routePrefix = '/candidato';
          break;
        case 'Empresa':
          this.routePrefix = '/empresa';
          break;
        default:
          this.routePrefix = '/administrativo';
          break;
      }
    }
  }

  onConsent(): void {
    this.pixel.initialize();
    this.pixel.track("ViewContent", {
      content_name: "Aceita termos"
    });
  }

  acceptTerms() {
    abp.ui.setBusy();
    this._termosService
      .acceptLast()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe(() => {
        abp.message.success(this.l("SavedSuccessfully"), this.l("Sucesso"));
        this.bsModalRef.hide();
      });
  }

  public get TipoTermoEnum() {
    return TipoTermoEnum;
  }
}
