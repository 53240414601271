<div class="modal-body rounded-bottom p-10 text-center mat-elevation-z3">
    <p class="p-5 mb-5">
        Parece que você está se candidatando a uma vaga. Por favor, selecione o modo com o qual deseja concluir o
        cadastro:
    </p>
    <button *ngFor="let selectionOption of selectionOptions" type="submit"
        class="btn btn-primary default-button nav-button px-6 py-3 mat-elevation-z3 mb-5 d-block w-100"
        (click)="onSelectOption(selectionOption)">
        {{ selectionOption }}
    </button>
</div>