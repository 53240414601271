import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import {
  CargoServiceProxy,
  EnumDto,
  CidadeMicrorregiaoDto,
  CidadeMicrorregiaoServiceProxy
} from '@shared/service-proxies/service-proxies';

import { Filtro } from '../../../../shared/components/filtro/models/filtro.model';
import { FiltroService } from '../../../../shared/components/filtro/service/filtro.service';
import { AppComponentBase } from '@shared/app-component-base';
import { JsonDataService } from '@shared/services/json-data.service';
import { SelectItem } from './select-item.interface';
import { ToggleCustomComponent } from './toggle-custom/toggle-custom.component';
import { SliderTickCustomComponent } from './slider-tick-custom/slider-tick-custom.component';
import { SelectBoxCustomComponent } from './select-box-custom/select-box-custom.component';

@Component({
  selector: "filtro-vitrine",
  templateUrl: './filtro-vitrine.component.html',
  styleUrls: ["./filtro-vitrine.component.scss"],
  animations: [appModuleAnimation()]
})
export class FiltroVitrineComponent extends AppComponentBase implements OnInit {
  @ViewChild(ToggleCustomComponent) toggleCustomComponent: ToggleCustomComponent;
  @ViewChild(SliderTickCustomComponent) sliderTickCustomComponent: SliderTickCustomComponent;
  @ViewChild(SelectBoxCustomComponent) selectBoxCustomComponent: SelectBoxCustomComponent;

  filtro: Filtro;
  @Input() isOpenSmallScreen: boolean = false;
  @Input() empresas: SelectItem[] = [];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() listaModeloTrabalho: EnumDto[] = [];
  @Input() max: number = 0;
  @Input() isLoggedIn: boolean = false;
  
  modeloTrabalho: any;
  isToggled: boolean = false;
  salario = 0;

  estados: any[];
  cidades: any[];
  selectedEstado: SelectItem = this.createSelectItem();
  selectedCidade: SelectItem = this.createSelectItem();
  selectedCargo: SelectItem = this.createSelectItem();
  selectedEmpresa: SelectItem = this.createSelectItem();
  nomeEstadoSelecionado: string = '';
  nomeCidadeSelecionada: string = '';
  desabilitarCidade = true;

  cargos: SelectItem[] = [];
  selectedCargos: string[] = [];

  nomeEmpresa: string = "";
  pcd: boolean = false;

  pcdTitle: string = this.l("Marque para exibir vagas destinadas à pessoas com deficiência.");
  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    public filtroService: FiltroService,
    private dataService: JsonDataService,
    private _cargoService: CargoServiceProxy,
    private _cidadesMicrorregioesService: CidadeMicrorregiaoServiceProxy,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    const savedFiltro = this.filtroService.getFiltro();
    if (savedFiltro) {
      this.filtro = savedFiltro;
      this.isToggled = this.filtro.pcd;
      this.selectedEmpresa.item_text = this.filtro.nomeEmpresa || "";
      this.selectedEmpresa.item_id = this.filtro.empresaId || "";
      this.nomeEstadoSelecionado = this.filtro.estado || "";
      this.nomeCidadeSelecionada = this.filtro.cidade || "";
      this.getEstadoCidadeCache(this.filtro.estado, this.filtro.cidade);
      this.modeloTrabalho = this.filtro.modeloTrabalho ?? null;
      this.salario = this.filtro.salario ?? 0;
    } else {
      this.filtro = {};
    }

    this.filtroService.getFiltroUpdateListener().subscribe(filtro => {
      this.filtro = filtro;
    });

    this.loadEstados();
    this.loadCargos();
    this.cdr.markForCheck();
  }

  createSelectItem(item_id: any = null, item_text: string = null): { item_id: any, item_text: string } { return { item_id: item_id, item_text: item_text }; }

  getEstadoCidadeCache(estado, cidade) {
    let siglaEstadoSelecionado = estado || "";
    if (siglaEstadoSelecionado) {
      let nomeEstadoSelecionado = this.dataService.getEstadoNome(siglaEstadoSelecionado);
      this.selectedEstado.item_id = estado;
      this.selectedEstado.item_text = nomeEstadoSelecionado;
      if (cidade)
        this.loadCidades(estado, cidade)
    }
  }

  onSelectModeloTrabalho(value: number) {
    if (!this.isOpenSmallScreen)
      this.filtroService.setModelo(value);
    else
      this.modeloTrabalho = value;
  }

  onSelectSalario(value: number) {
    if (!this.isOpenSmallScreen)
      this.filtroService.setSalario(value);
    else
      this.salario = value;
  }

  onSelectPcD(value: boolean) {
    if (!this.isOpenSmallScreen)
      this.filtroService.setPcd(value);
    else
      this.isToggled = value;
  }

  onSelectOrTypeEstado(value: SelectItem) {
    const idEstadoSelecionado = this.dataService.getEstadoId(value.item_id);
    let nomeEstadoSelecionado = '';
  
    if (idEstadoSelecionado) {
      nomeEstadoSelecionado = this.dataService.getEstadoSigla(idEstadoSelecionado);
      this.loadCidades(nomeEstadoSelecionado);
    } else {
      this.cidades = [];
      this.selectedCidade = this.createSelectItem();;
    }
  
    if (!this.isOpenSmallScreen) {
      this.filtroService.setEstado(nomeEstadoSelecionado);
    } else {
      this.nomeEstadoSelecionado = nomeEstadoSelecionado;
    }
  }

  onSelectOrTypeCidade(value: SelectItem) {
    this.nomeCidadeSelecionada = value.item_text;
    this.selectedCidade = value.item_text ? value : this.createSelectItem();

    if (!this.isOpenSmallScreen) {
      this.filtroService.setCidade(this.nomeCidadeSelecionada);
    }
  }

  onSelectOrTypeCargo(value: SelectItem) {
    this.selectedCargos = value.item_id ? [value.item_id] : [];
    this.selectedCargo = value.item_id ? value : null;

    if (!this.isOpenSmallScreen) {
      this.filtroService.setCargos(this.selectedCargos);
    }
  }

  onSelectOrTypeEmpresa(value: SelectItem) {
    if (!this.isOpenSmallScreen) {
      this.selectedEmpresa = value;
      this.filtroService.setNomeEmpresa(this.selectedEmpresa.item_text);
    } else {
      this.selectedEmpresa = value;
    }
  }

  filtrar() {
    this.filtro = {};

    if (this.modeloTrabalho !== null) {
      this.filtro.modeloTrabalho = this.modeloTrabalho;
    }

    this.filtro.salario = this.salario;
    this.filtro.pcd = this.isToggled;
    this.filtro.estado = this.nomeEstadoSelecionado;
    this.filtro.cidade = this.nomeCidadeSelecionada;

    this.filtro.cargosId = this.selectedCargo && this.selectedCargo.item_id ? [this.selectedCargo.item_id] : [];
    this.filtro.empresaId = this.selectedEmpresa && this.selectedEmpresa.item_id || undefined;
    this.filtro.nomeEmpresa = this.selectedEmpresa && this.selectedEmpresa.item_text || '';

    if (!this.selectedEmpresa || !this.selectedEmpresa.item_id && !this.selectedEmpresa.item_text) {
      this.filtro.nomeEmpresa = '';
    }

    this.filtroService.setFiltro(this.filtro, this.isLoggedIn);
    this.fecharFiltro();
  }

  fecharFiltro() {
    this.closeModal.emit();
  }

  limparFiltro() {
    this.filtro = {};

    this.setVariaveisLimparFiltro();

    this.filtroService.setFiltro(this.filtro, this.isLoggedIn);

    this.cdr.markForCheck();
    
    this.fecharFiltro();
  }

  setVariaveisLimparFiltro() {
    this.selectedEmpresa = this.createSelectItem();
    this.selectedCargo = this.createSelectItem();
    this.isToggled = false;
    this.toggleCustomComponent.resetToggle();
    this.selectedEstado = this.createSelectItem();
    this.selectedCidade = this.createSelectItem();
    this.desabilitarCidade = true;
    this.salario = 0;
    this.sliderTickCustomComponent.resetSalario();
    this.modeloTrabalho = undefined;
    this.selectBoxCustomComponent.limparCheckBox();
  }

  loadEstados() {
    this.dataService.getEstados().subscribe(estados => {
      this.estados = estados.map((estado: any) => ({
        item_id: estado.Sigla,
        item_text: estado.Nome,
      }));
    });
  }

  loadCidades(estado, cidadeInput = null) {
    this._cidadesMicrorregioesService.getCidadesEstado(estado)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((cidadesMicrorregioes: CidadeMicrorregiaoDto[]) => {
        this.cidades = cidadesMicrorregioes.map((cidadeMicrorregiao: CidadeMicrorregiaoDto) => ({
          item_id: cidadeMicrorregiao.ibgeId,
          item_text: cidadeMicrorregiao.cidade,
        }));
        if (cidadeInput)
          this.selectedCidade = this.cidades.find((cidade: SelectItem) => cidade.item_text == cidadeInput)
        this.desabilitarCidade = false;
        abp.ui.clearBusy();
      });
  }


  loadCargos() {
    abp.ui.setBusy();
    this._cargoService.getAll()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe(x => {
        if (x.length > 0) {
          this.cargos = x.map((carg: any, index: number) => ({
            item_id: carg.id,
            item_text: carg.nome,
          }));
        }
        if (this.filtro.cargosId)
          this.selectedCargo = this.cargos.find((e) => e.item_id == this.filtro.cargosId[0]);
      });
  }

  hasActiveFilters(): boolean {
    return (
      Boolean(this.filtro.pcd) ||
      Boolean(this.filtro.salario > 0) ||
      Boolean(this.filtro.estado) ||
      Boolean(this.filtro.cidade) ||
      (Array.isArray(this.filtro.cargosId) && this.filtro.cargosId.length > 0) ||
      Boolean(this.filtro.empresaId) ||
      Boolean(this.filtro.nomeEmpresa) ||
      this.filtro.modeloTrabalho !== undefined && this.filtro.modeloTrabalho !== null
    );
  }

}
