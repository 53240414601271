export class GoogleAnalyticsAcoes{
    public static acessohome = "Acesso à página de Home";
    public static acessoLogin = "Acesso à página de Login";

    //ADMINISTRATIVO
    public static acessoHomeAdministrador = "Acesso à home para administradores";
    public static acessoUserAdministrador = "Acesso à página de usuários para administradores";
    public static acessoEmpresaAdministrador = "Acesso à página de empresas para administradores";
    public static acessoCandidatosAdministrador = "Acesso à página de candidatos para administradores";
    public static acessoContratadosAdministrador = "Acesso à página de contratados para administradores";
    public static acessoVagasAdministrador = "Acesso à página de vagas para administradores";
    public static acessoComunidadeAdministrador = "Acesso à página de comunidade para administradores";
    public static acessoLogoEmpresaAdministrador = "Acesso à página de logo de empresas para administradores";
    public static acessoFuncoesAdministrador = "Acesso à página de funcoes para administradores";
    public static acessoTermoAdministrador = "Acesso à página de termos de uso para administradores";
    public static acessoPoliticaAdministrador = "Acesso à página de política de privacidade para administradores";
    public static acessoPerfilAdministrador = "Acesso à página de perfil de administradores";
    public static acessoChangePasswordAdm = "Acesso à página de mudar senha de administradores";
    //PARA CANDIDATOS
    public static acessoCriarContaCandidatos = "Acesso página de criar contas para candidatos";
    public static acessoLoginCandidato = "Acesso página de login para candidatos";
    public static acessoHomeCandidatos = "Acesso página de home para candidatos";
    public static acessoInscricoesCandidatos = "Acesso à página de inscrições para candidatos";
    public static acessoCurriculoCandidatos = "Acesso à página de curriculo para candidatos";
    public static acessoPerfilCandidatos = "Acesso à página de perfil para candidatos";
    public static acessoQuemSomosCandidatos = "Acesso à página de Quem Somos para candidatos";
    //PARA EMPRESAS
    public static acessoCriarContaEmpresas = "Acesso página de criar conta para empresas";
    public static acessoLoginEmpresa = "Acesso página de login para empresas";
    public static acessoHomeEmpresas = "Acesso página de home para empresas";
    public static acessoPerfilEmpresas = "Acesso à página de perfil para empresas";
    public static acessoCriarVagaEmpresa = "Acesso à página de criar vagas da empresa";
    public static acessoEditarVagaEmpresa = "Acesso à página de editar vagas da empresa";
    public static acessoVisualizarVagaEmpresa = "Acesso à página de visualizar detalhes de vaga da empresa";
    public static acessoInscritosEmVagaEmpresa = "Acesso à página de inscritos em vaga da empresa";
    public static acessoVagaCandidatoEmpresa = "Acesso à página de detalhes de candidato em vaga da empresa";
    public static acessoQuemSomosEmpresas = "Acesso à página de Quem Somos para empresas";
    //VAGAS
    public static acessoVagas = "Acesso à página de vagas";
    public static acessoVagaDetalhe = "Acesso à página Detalhe da vaga";
    public static acessoVitrineVagas = "Acesso à página de vitrine de vagas";
    //INFORMATIVO
    public static acessoQuemSomos = "Acesso à página Quem Somos";
    public static acessoTermoDeUso = "Acesso à página de termo de uso";
    public static acessoPpoliticaPrivacidade = "Acesso à página de política de privacidade";
    //SERVIÇOS
    public static acessoCancelarEnvioEmail = "Acesso à página de cancelar envio de email";
    public static acessoResetPassword = "Acesso à página de alterar senha";
}
