import {
  Component,
  EventEmitter,
  Injector,
  Output,
} from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
selector: "app-login-or-create-selection-dialog",
templateUrl: "./login-or-create-selection-dialog.component.html",
styleUrls: ["./login-or-create-selection-dialog.component.css"],
})
export class LoginOrCreateSelectionDialogComponent
extends AppComponentBase
{
public selectionOptions: Array<string> = ["Fazer Login","Criar Conta"];

@Output() optionSelected = new EventEmitter<string>();

constructor(
  public injector: Injector,
  public bsModalRef: BsModalRef,
) {
  super(injector);
}
ngOnInit(): void {
}

onSelectOption(option: string) {
  this.optionSelected.emit(option);
  this.bsModalRef.hide();
}
}
