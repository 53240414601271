import { ChangeDetectorRef, Component, Injector, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import { FiltroService } from '../../../../shared/components/filtro/service/filtro.service';
@Component({
    selector: "card-ordenacao-vaga",
    templateUrl: "./card-ordenacao-vaga.component.html",
    styleUrls: ["./card-ordenacao-vaga.component.scss"],
})
export class CardOrdenacaoComponent extends AppComponentBase implements OnInit {

    ordenacao: string = 'mais_recente';
    textobusca: string = '';

    constructor(
        injector: Injector,
        private cdr: ChangeDetectorRef,
        public filtroService: FiltroService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.cdr.markForCheck();
    }

    selectOption(event: Event, opcao: string): void {
        event.preventDefault();
        this.ordenacao = opcao;
        this.filtroService.setOdenacao(this.ordenacao);
        this.cdr.markForCheck();
      }

    updateSearchText(value: string): void {
        this.textobusca = value;
        this.filtroService.setTextoBusca(this.textobusca);
    }
}