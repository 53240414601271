<pagination-template #p="paginationApi" [id]="id" [maxSize]="maxSize" (pageChange)="pageChange.emit($event)">
  <div class="col-12 container-paginacao mb-10 mt-5">
    <div class="caixa-botao">
      <button class="btn botao-anterior-page" (click)="p.setCurrent(p.getCurrent() - 1)" [disabled]="p.getCurrent() === 1">
        <i class="fa fa-arrow-left arrow-icon"></i> Página Anterior
      </button>

      <button class="btn botao-proxima-page" (click)="p.setCurrent(p.getCurrent() + 1)" [disabled]="p.getCurrent() === p.pages.length">
        Próxima Página <i class="fa fa-arrow-right arrow-icon"></i>
      </button>
    </div>

    <div class="container-pagina">
      <div class="pagination-container">
        <span>Página</span>
        <select class="page-select" (change)="p.setCurrent($event.target.value)">
          <option *ngFor="let page of p.pages" [value]="page.value" [selected]="p.getCurrent() === page.value">
            {{ page.label }}
          </option>
        </select>
        <span>de {{ p.pages.length }}</span>
      </div>
    </div>
  </div>
</pagination-template>
