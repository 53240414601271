<div class="modal-header header">
    <h4 class="text-center title">Deseja enviar e-mail?</h4>
</div>
<div class="modal-body">
    <div class="form-group pt-5">
        <p class="text text-center">
            {{frase}}
        </p>
        <div>
            <div class="d-flex justify-content-center mt-5">
                <input type="button" value="Não" class="btn w-20 btn-red mr-3" (click)="close()">
                <input type="button" value="Sim" (click)="enviar()" class="btn btn-outline-primary w-20">
            </div>

        </div>
    </div>