<div class="input-select-container">
  <div class="input-group">
    <div class="input-with-arrow">
      <input type="text" class="form-control" maxlength="12" [placeholder]="'Digite o salário'"
        (blur)="onSalarioFilter()" [(ngModel)]="salarioValor" (ngModelChange)="onInputChange()" currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" inputmode="numeric" />
      <span class="input-icon" (click)="onSalarioFilter()">
        <img class="img-pesquisa" src="../../assets/img/vitrine-vagas/lupa.svg" alt="Lupa">
      </span>
    </div>
  </div>
</div>


<div class="slider-container">

  <div class="slider-bubble" [style.left.%]="(sliderValue / maxValue) * 100">
    {{ sliderValue | currency: 'BRL': 'symbol': '1.2-2' }}
  </div>

  <input type="range" min="0" [max]="maxValue" [(ngModel)]="sliderValue" (input)="onSliderInput($event)"
    (change)="onSliderChange($event)" class="slider">

</div>
