import { Component, Injector, OnInit } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import { GoogleAnalyticsAcoes } from "@shared/constant/google-analytics-acoes.model";
import { Pages } from "@shared/constant/peges.model";
import { TermosServiceProxy, TipoTermoEnum } from "@shared/service-proxies/service-proxies";
import { GoogleAnalyticsService } from "@shared/services/google-analytics.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  animations: [appModuleAnimation()],
})
export class PrivacyPolicyComponent extends AppComponentBase implements OnInit {
  public privacyPolicy: string;

  constructor(
    injector: Injector,
    private _termosService: TermosServiceProxy,
    private _googleAnalyticsService: GoogleAnalyticsService,
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.privacyPolicy = '';
    this.getPrivacyPolicy();
    this.googleAnalyticsEventSend();
  }

  googleAnalyticsEventSend() {
    this._googleAnalyticsService.sendEmiter(GoogleAnalyticsAcoes.acessoPpoliticaPrivacidade, {
      event_label: Pages.politicaPrivacidade
    });
  }

  getPrivacyPolicy() {
    abp.ui.setBusy();
    this._termosService
      .getLatestTermo(TipoTermoEnum._0)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((termo) => {
          this.privacyPolicy = termo.texto || "";
      });
  }
}
