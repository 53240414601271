import { ChangeDetectorRef, Component, EventEmitter, HostListener, Injector, Input, OnInit, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
  selector: 'slider-tick-custom',
  templateUrl: './slider-tick-custom.component.html',
  styleUrls: ['./slider-tick-custom.component.scss'],
  animations: [appModuleAnimation()]
})
export class SliderTickCustomComponent extends AppComponentBase implements OnInit {

  @Input() salarioValor: number = 0;
  @Input() salarioValue: number = 0;
  @Input() sliderValue: number = 0;
  maxValue: number = 10000;
  @Output() salarioChange = new EventEmitter<number>();

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.salarioValor)
      this.sliderValue = this.salarioValor;
    this.updateSliderVisuals();
  }

  updateSliderVisuals() {
    document.documentElement.style.setProperty('--slider-value', this.sliderValue.toString());
    document.documentElement.style.setProperty('--slider-max', this.maxValue.toString());
  }

  onSliderInput(event: any) {
    this.sliderValue = event.target.value;
    this.salarioValor = this.sliderValue;
    this.updateSliderVisuals();
  }

  onSliderChange(event: any) {
    this.salarioValue = event.target.value;
    this.salarioChange.emit(this.salarioValue);
  }

  onInputChange(): void {
    if (this.salarioValor > 10000) {
      this.salarioValor = 10000;
    }

    this.sliderValue = this.salarioValor;
    this.updateSliderVisuals();
  }

  onSalarioFilter() {
    this.salarioChange.emit(this.salarioValor);
  }

  resetSalario(): void{
    this.salarioValor = 0;
    this.sliderValue = 0;
    this.updateSliderVisuals();
  }
}
