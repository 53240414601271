<div>
  <div class="border-bottom p-3 bg-light rounded-top">
    <div class="text-center p-3">
      <div class="title">
        Alteração nos Termos de Uso e Política de Privacidade
      </div>
    </div>
  </div>
  <div class="modal-body rounded-bottom">
    <div class="text-center">
      Atenção! Nossos
      <a
        [routerLink]="routePrefix + '/termos-de-uso'"
        target="_blank"
        class="text-primary font-weight-bold"
        >Termos de Uso</a
      >
      e
      <a
        [routerLink]="routePrefix + '/politica-de-privacidade'"
        target="_blank"
        class="text-primary font-weight-bold"
        >Política de Privacidade</a
      >
      foram atualizados. Para continuar utilizando nossa plataforma, é
      necessário que você aceite as alterações. Por favor, leia cuidadosamente
      as novas condições e clique em 'Aceitar' se concordar com elas. Se tiver
      alguma dúvida, não hesite em entrar em contato conosco. Obrigado por
      utilizar nossos serviços.
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn btn-primary default-button nav-button px-6 py-3 mat-elevation-z3 mt-10"
        (click)="acceptTerms()"
      >
        Aceitar
      </button>
    </div>
  </div>
</div>
