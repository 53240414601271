<div class="row">

    <div *ngIf="this.isSmallScreen == false" class="align-items-stretch px-10 mb-5 filtro">
        <filtro-vitrine [max]="max" [listaModeloTrabalho]="listaModeloTrabalho"
            *ngIf="loadEmpresas == true && max > 0 && listaModeloTrabalho.length > 0" [empresas]="empresas"
            [isOpenSmallScreen]="false" [isLoggedIn]="isLoggedIn()"></filtro-vitrine>
    </div>


    <div *ngIf="this.isSmallScreen == true" class="botao-filtro mb-12" (click)="abrirFiltro()">
        <img class="img-pesquisa" src="../../assets/img/vitrine-vagas/filtro.svg" alt="Filtro">
        <span>Filtrar resultados</span>
    </div>


    <div id="vagas-container">
        <div class="pesquisa-container mb-12">
            <div class="row p-5">
                <div class="col-12 col-sm-9 col-md-9 col-lg-9 text-pesquisa-container">
                    <h4 class="color-white-ice font-600 title-pesquisa">
                        Junte-se à nossa comunidade e encontre o emprego ideal para você.
                    </h4>
                    <div class="input-group search-bar">
                        <input type="text" class="form-control search-input" [placeholder]="placeholderText"
                            [(ngModel)]="textobusca" name="textobusca" (blur)="updateSearchText()" (keydown.enter)="updateSearchText()">
                        <img *ngIf="this.textobusca" class="img-trash mr-3 p-0" src="../../../assets/img/vitrine-vagas/lixeira.svg" alt="Lixeira" (click)="limparFiltros()">
                        <div class="input-group-append">
                            <button class="btn search-button" type="button" (click)="updateSearchText()">
                                <i class="fa fa-search"></i> Buscar
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 maleta">
                    <div class="float-right img-pesquisa-container">
                        <img class="img-pesquisa" src="../../assets/img/vitrine-vagas/maleta.svg" alt="Maleta">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-10">
            <div class="row justify-content-between align-items-center">
                <div class="ordenacao-pesquisa d-flex align-items-center">
                    <span *ngIf="this.filtro?.textoBusca && this.filtro.textoBusca.trim() !== ''"
                        class="me-2 vagas-encontradas ordenacao-pesquisa-margin">{{ this.filtro?.textoBusca }} -
                        {{ totalItems }} vagas encontradas
                    </span>
                </div>

                <div class="ordenacao-coluna d-flex align-items-center">
                    <span class="me-2 ordenacao-title">Ordenação:</span>
                    <card-ordenacao-vaga></card-ordenacao-vaga>
                </div>
            </div>
        </div>

        <h3 class="title-vagas-regiao mb-10" *ngIf="checkNaoHaVagasDisponiveisFiltradas()">Não encontramos vagas que
            atendam aos
            requisitos filtrados no momento</h3>

        <h3 class="title-vagas-regiao mb-10" *ngIf="checkVagaSuaRegiao()">Vagas na sua
            região</h3>

        <h3 class="title-vagas-regiao mb-12 px-3" *ngIf="checkNaoPossuiVagasAbertasRegiao()">Sua
            região não possui vagas abertas no momento</h3>

        <h3 class="title-vagas-regiao mb-12 px-3" *ngIf="checkNaoPossuiVagasAbertasRegiaoRequisitosFiltrados()">Sua
            região não
            possui vagas abertas que atendam aos requisitos filtrados</h3>

        <div class="col-12 mb-3">
            <div class="row">
                <ng-container *ngIf="vagasMicrorregiao && vagasMicrorregiao.length > 0">
                    <div class="col-sm-6 col-12 col-md-6 col-lg-6 mb-4 custom-cards"
                        *ngFor="let vaga of vagasMicrorregiao">
                        <card-candidato-vaga [vaga]="vaga" [isInscrito]="isInscrito(vaga)"></card-candidato-vaga>
                    </div>
                </ng-container>

                <h3 class="title-vagas-regiao mb-10"
                    *ngIf="checkVagasOutrasRegioes() || checkVagasRegiaoUsuarioEOutras()">
                    Vagas em outras regiões</h3>

                <div class="col-sm-6 col-12 col-md-6 col-lg-6 mb-4 custom-cards" *ngFor="let vagaGeral of vagasGeral | paginate: {
                            id: 'server',
                            itemsPerPage: pageSize,
                            currentPage: pageNumber,
                            totalItems: totalItems
                          }">
                    <card-candidato-vaga [vaga]="vagaGeral" [isInscrito]="isInscrito(vagaGeral)"></card-candidato-vaga>
                </div>
            </div>
        </div>

        <abp-pagination-custom-controls *ngIf="totalItems > 0" id="server" (pageChange)="getDataPage($event)">
        </abp-pagination-custom-controls>

    </div>
</div>
