// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    isMockEnabled: true,
    appConfig: 'appconfig.production.json',
    apiCnpj: 'https://api-publica.speedio.com.br/buscarcnpj?cnpj=',

    //METRONIC
    appVersion: 'v8.1.6',
    USERDATA_KEY: 'authf649fc9a5f55',
    apiUrl: 'api',
    appThemeName: 'Metronic',
    appPurchaseUrl: 'https://1.envato.market/EA4JP',
    appHTMLIntegration:
      'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
    appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
    appPreviewAngularUrl:
      'https://preview.keenthemes.com/metronic8/angular/demo1',
    appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
    appPreviewChangelogUrl:
      'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
};
