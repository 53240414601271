<section class="content-header">
  <div class="row mb-10 d-flex align-items-center">
    <div class="col-md-4">
      <h1 class="title-filtro">Filtros</h1>
    </div>
    <div class="col-md-7 d-flex justify-content-center" *ngIf="hasActiveFilters() && this.isOpenSmallScreen == false"
      (click)="limparFiltro()">
      <img class="img-pesquisa" src="../../assets/img/vitrine-vagas/lixeira.svg" alt="Filtro">
      <a href="javascript:;" class="detalhes-link">Limpar filtros</a>
    </div>
  </div>


  <p class="subtitle mb-8">Dados da vaga</p>

  <p class="subtitle-inputs mb-3">Empresas</p>
  <input-select-text *ngIf="selectedEmpresa" [valores]="empresas" [inputLivre]="true"
    (selectedOptionChange)="onSelectOrTypeEmpresa($event)" [selectValor]="selectedEmpresa"
    [placeholder]="'Escolha uma empresa'"></input-select-text>

  <p class="subtitle-inputs mb-3">Cargos</p>
  <input-select-text *ngIf="cargos && cargos.length > 0 && selectedCargo" [valores]="cargos"
    [selectValor]="selectedCargo" (selectedOptionChange)="onSelectOrTypeCargo($event)"
    [placeholder]="'Escolha um cargo'"></input-select-text>

  <p class="subtitle mt-8 mb-5">Vagas exclusivas para PcD</p>
  <div class="row mb-12">
    <toggle-custom [(value)]="isToggled" (toggleChange)="onSelectPcD($event)"></toggle-custom>
  </div>

  <p class="subtitle mb-8">Localização</p>
  <p class="subtitle-inputs mb-3">Estado</p>
  <input-select-text *ngIf="selectedEstado" [valores]="estados" (selectedOptionChange)="onSelectOrTypeEstado($event)"
    [selectValor]="selectedEstado" [placeholder]="'Selecione um Estado'"></input-select-text>
  <p class="subtitle-inputs mb-3">Cidade</p>
  <input-select-text *ngIf="selectedCidade" [valores]="cidades"
    [placeholder]="desabilitarCidade ? 'Primeiro escolha um Estado' : 'Selecione uma cidade'"
    [disabled]="desabilitarCidade" [selectValor]="selectedCidade" (selectedOptionChange)="onSelectOrTypeCidade($event)"></input-select-text>

  <p class="subtitle mb-8 mt-10">Salário</p>
  <slider-tick-custom (salarioChange)="onSelectSalario($event)" [salarioValor]="salario"></slider-tick-custom>

  <p class="subtitle mt-8">Modelo de trabalho</p>
  <select-box-custom *ngIf="listaModeloTrabalho && listaModeloTrabalho.length > 0"
    [listaModeloTrabalho]="listaModeloTrabalho" (selectedOptionChange)="onSelectModeloTrabalho($event)"
    [selectedOption]="modeloTrabalho"></select-box-custom>

  <div *ngIf="isOpenSmallScreen" class="col-12 mt-10 mb-8 p-0">
    <button class="btn-aplicar-filtros" (click)="filtrar()">
      Aplicar filtros
    </button>
  </div>

  <div *ngIf="isOpenSmallScreen" class="col-12 p-0 mb-10">
    <button class="btn-limpar-filtros" [ngClass]="{'btn-active': hasActiveFilters()}" [disabled]="!hasActiveFilters()"
      (click)="limparFiltro()">
      Limpar filtros
    </button>
  </div>
</section>
