import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import {
  map,
  startWith
} from "rxjs/operators";

@Component({
  selector: "filterable-select",
  templateUrl: "./filterable-select.component.html",
  styleUrls: ["./filterable-select.component.css"],
})
export class FilterableSelectComponent<T> implements OnInit {
  @Input() options: T[];
  @Input() optionLabel: string = "label";
  @Input() placeholder: string = "Selecione";
  @Input()
  set selected(value: T) {
    this._selected = value;
    this.setNewLabel(value);
  }

  get selected(): T {
    return this._selected;
  }

  @Output() selectedOption = new EventEmitter<T>();
  @Output() selectedChange = new EventEmitter<T>();
  @Output() onInputChanged = new EventEmitter<string>();

  private _selected: T;
  public filteredOptions: Observable<T[]>;
  public control = new FormControl();

  public displayFn = (option: T): string => {
    return option ? this.getOptionLabel(option) : '';
  }

  constructor() { }

  setNewLabel(option: T) {
    if (option) {
      this.control.setValue(this.getOptionLabel(option));
    } else {
      this.control.setValue('');
    }
  }

  ngOnInit(): void {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : this.getOptionLabel(value)),
      map(name => name ? this._filter(name) : this.options.slice())
    );
  }

  private _filter(value: string): T[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((o) =>
      this.getOptionLabel(o).toLowerCase().includes(filterValue)
    );
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const value: T = event.option.value;
    this.selectedChange.emit(value);
    this.selectedOption.emit(value);
    this.onInputChanged.emit(value[this.optionLabel]);
  }

  public onOptionTyped(typed: string) {
    const filterValue = typed.toLowerCase();
    var optionSelect = this.options.find((o) =>
      this.getOptionLabel(o).toLowerCase() === filterValue
    );
    if (optionSelect != undefined) {
      this.selectedChange.emit(optionSelect);
      this.selectedOption.emit(optionSelect);
      this.onInputChanged.emit(typed);
    } else {
      this.onInputChanged.emit(typed);
    }
  }


  public getOptionLabel(option: T): string {
    return typeof option === "string"
      ? option
      : (option[this.optionLabel] as string);
  }
}
