import { Component, Injector, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
  selector: 'toggle-custom',
  templateUrl: './toggle-custom.component.html',
  styleUrls: ['./toggle-custom.component.scss'],
  animations: [appModuleAnimation()]
})
export class ToggleCustomComponent extends AppComponentBase implements OnInit, OnChanges {

  @Input() value: boolean = false;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && !changes['value'].firstChange) {
      this.cdr.detectChanges();
    }
  }

  toggle(): void {
    this.value = !this.value;
    this.toggleChange.emit(this.value);
  }

  resetToggle(): void {
    this.value = false;
  }
}
