export class DropdownSettings {
    static getSettings(singleSelectionOption: Boolean = true) {
        return {
            singleSelection: singleSelectionOption,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Selecionar todos',
            unSelectAllText: 'Desmarca todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar',
            noDataLabel: "Sem dados disponíveis",
            enableSearchFilter: true,
            disabled: false,
            badgeShowLimit: 3,
            noFilteredDataAvailablePlaceholderText: 'Nenhum dado filtrado disponível'
        }
    }
}