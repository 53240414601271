<div class="select-box">
  <div class="option" *ngFor="let modelo of listaModeloTrabalho">
    <input
      [id]="modelo.value"
      type="radio"
      name="attendanceOption"
      [(ngModel)]="selectedOption"
      [value]="modelo.value"
      (change)="onOptionChange(modelo.value)"
    />
    <label [for]="modelo.value" class="custom-checkbox"></label>
    <span>{{ getDisplayName(modelo.name) }}</span>
  </div>
</div>
