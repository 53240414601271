<div class="modal-fullscreen p-10">
  <div class="modal-header px-10">
    <div class="container-close" (click)="close()">
      <div>
        <img src="../../assets/img/vitrine-vagas/close.svg" alt="Close">
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="modal-content mt-5 px-10">
    <filtro-vitrine [max]="max" [listaModeloTrabalho]="listaModeloTrabalho" [empresas]="empresas" [isOpenSmallScreen]="true" (closeModal)="close()" [isLoggedIn]="isLoggedIn"></filtro-vitrine>
  </div>
</div>
