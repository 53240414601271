import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`
})
export class RootComponent implements OnInit {

    constructor(private router: Router, private _gaService: GoogleAnalyticsService) { }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this._gaService.trackPageView(event.urlAfterRedirects);
        });
    }
}
