<div [@routerTransition] class="paginaCompleta" [ngClass]="{'m-header': !appSession.userId}">
  <div class="container py-10">
    <div class="col-12 col px-5 mb-5">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-6">
          <button class="btn btn-light btn-lg text-primary px-10 py-2 w-100 w-md-auto" (click)="goBack()">←
            Voltar para a lista de vagas</button>
        </div>
        <div class="title-primary">
          Detalhes da vaga
        </div>
        <div class="title titulo-vaga justify-content-center"> {{ vaga.tituloVaga }} </div>
        <div class="data-publicacao"> Publicada em {{getFormattedDate(vaga.dataInicio)}}</div>

        <div class="row align-items-center">
          <div class="col-12 col-md-6 d-flex justify-content-md-start mb-3 mb-md-0">
            <button *ngIf="!isInscrito" type="button"
              class="btn btn-green px-6 py-3 text-primary btn-candidate" (click)="candidatar()">
              <span class="subtitle-button">Candidatar-se</span>
            </button>
          </div>

          <div class="col-12 col-md-6 d-flex flex-column align-items-start align-items-md-end mt-3 mt-md-0">
            <span class="share-label mb-2">Compartilhar esta vaga:</span>

            <div class="social-icons d-flex flex-wrap">
              <!-- TODO: Não será usado no momento - NÃO REMOVER -->
              <!-- <a id="facebook-share" class="social-btn" aria-label="Compartilhar no Facebook">
                <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/facebook-icon.svg" alt="Facebook">
              </a>
              <a id="linkedin-share" class="social-btn" aria-label="Compartilhar no LinkedIn">
                <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/linkedin-icon.svg" alt="LinkedIn">
              </a> -->
              <a id="whatsapp-share" class="whatsapp-share social-btn" aria-label="Compartilhar no WhatsApp">
                <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/whatsapp-icon.svg" alt="WhatsApp">
              </a>
              <a role="button" class="social-btn" (click)="copyLink($event)" aria-label="Copiar link">
                <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/link-share-icon.svg" alt="Link Share">
              </a>
            </div>
        </div>

        <div class="col-12">
          <img class="img-inscrito img-fluid" *ngIf="isInscrito" src="../../../../../assets/img/candidatou.png" alt="inscrito" />
        </div>
      </div>
    </div>
    <mat-divider class="mb-10 mt-10"></mat-divider>
    <div class="rounded-border align-items-center">
      <div class="title-primary" style="margin-top: 0;">
        Empresa
      </div>
      <div class="col d-flex align-items-center mb-5 card-empresa">
        <div class="square-image ml-2">
          <img *ngIf="!vaga.confidencial" class="perfil-image"
            [src]="vaga.empresa?.urlFotoPerfil != undefined || vaga.empresa?.urlFotoPerfil != null ? vaga.empresa?.urlFotoPerfilDesbloqueado : '../../assets/img/user-void.png'"
            alt="perfil-image" />
          <img *ngIf="vaga.confidencial" class="perfil-image" src="../../assets/img/vaga.png" alt="perfil-image" />
        </div>

        <div class="form-group flex-grow-1 d-flex align-items-center">
          <div class="row" style="min-height: 80px; margin-top: 50px;">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 text-nowrap">
              <div *ngIf="vaga.empresa != undefined && !vaga.confidencial">
                <h1 class="title-2 mb-2">{{ vaga.empresa.nome }}</h1>
              </div>
              <div class="mt-1 img-conf" *ngIf="vaga.confidencial">
                <img src="../../assets/img/confidencial.png" alt="vaga confidencial" width="160" />
              </div>
              <p *ngIf="!vaga.confidencial" class="align-self-start subtitle" style="font-weight:400 !important;">
                {{ vaga.cidade }} - {{ vaga.estado }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 ml-0" style="text-align: justify;">
        <div *ngIf="!vaga.confidencial" class="col-sm-12 col-md-6">
          <div *ngIf="vaga.empresa && vaga.empresa.endereco" class="row">
            <div class="col ml-0" style="padding-left: 0 !important;">
              <span class="flex-grow-1 subtitle px-2" style="padding-left: 0 !important;">Endereço:</span>
              <span class="title-3">{{vaga.empresa?.endereco?.logradouro}},
                {{vaga.empresa?.endereco?.bairro}}, nº {{vaga.empresa?.endereco?.numero}},
                {{vaga.empresa?.endereco?.cidade}}/{{vaga.empresa?.endereco?.estado}}
              </span>
              <span class="title-3" *ngIf="vaga.empresa?.endereco?.complemento">
                , {{vaga.empresa?.endereco?.complemento}}.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rounded-border input-container pb-3 mt-10">
      <div class="title-primary mb-3 ml-3 ">
        Dados da vaga
      </div>
      <div class="inputs-wrapper">
        <div *ngIf="vaga.dataTermino" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Inscrições até: {{ vaga.dataTermino | date:'dd/MM/yyyy' }}
          </span>
        </div>

        <div *ngIf="vaga.cargo" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Cargo: {{ vaga.cargo?.nome }}
          </span>
        </div>

        <div *ngIf="vaga.modeloTrabalhoString" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Modelo de trabalho: {{ vaga.modeloTrabalhoString }}
          </span>
        </div>

        <div *ngIf="(vaga.salario && vaga.exibirSalario == true) || (vaga.salario && (vaga.exibirSalario == null || vaga.exibirSalario == undefined))" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Salário: {{ vaga.salario | currency:'BRL':'symbol':'1.2-2' }}
          </span>
        </div>

        <div *ngIf="vaga.horasSemanais" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Horas semanais: {{ vaga.horasSemanais }}
          </span>
        </div>

        <div *ngIf="vaga.regimeTrabalhoString" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Regime de trabalho: {{ vaga.regimeTrabalhoString }}
          </span>
        </div>

        <div *ngIf="vaga.quantidadeVagas" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Vagas: {{ vaga.quantidadeVagas }}
          </span>
        </div>

        <div *ngIf="vaga.pcd" class="mx-2">
          <span class="badge bg-secondary badge-icons">
            Vaga exclusiva para PcD.
          </span>
        </div>
      </div>
    </div>


    <div class="rounded-border mt-10">
      <div class="title-primary mb-10">
        Descrição da Vaga
      </div>
      <div class="descricao-vaga">
          <p class="normal-text" [innerHTML]="vaga.descricao"></p>
      </div>
    </div>

    <div class="rounded-border mt-10 mb-10" *ngIf="vaga.competencias && vaga.competencias.length > 0">
      <div class="title-primary mb-10">Competência(s)</div>
      <div class="card-body table-responsive p-0">
            <tr *ngFor="let competencia of vaga.competencias">
              <td>
                <ng-container>
                  {{ competencia?.nome }}
                </ng-container>
              </td>
            </tr>
      </div>
    </div>

      <div class="row align-items-center mt-4">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <button *ngIf="!isInscrito" type="button"
            class="btn btn-green px-6 py-3 text-primary btn-candidate" (click)="candidatar()">
            <span class="subtitle-button">Candidatar-se</span>
          </button>
        </div>

        <div class="col-12 col-md-6 d-flex flex-column align-items-md-end mt-3 mt-md-0">
          <span class="share-label mb-2">Compartilhar esta vaga:</span>
          <div class="social-icons d-flex flex-wrap justify-content-md-end">
            <!-- TODO: Não será usado no momento - NÃO REMOVER -->
            <!-- <a href="#" class="social-btn" aria-label="Compartilhar no Facebook">
              <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/facebook-icon.svg" alt="Facebook">
            </a>
            <a href="#" class="social-btn" aria-label="Compartilhar no LinkedIn">
              <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/linkedin-icon.svg" alt="LinkedIn">
            </a> -->
            <a id="whatsapp-share" class="whatsapp-share social-btn" aria-label="Compartilhar no WhatsApp">
              <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/whatsapp-icon.svg" alt="WhatsApp">
            </a>
            <a role="button" class="social-btn" (click)="copyLink($event)" aria-label="Copiar link">
              <img class="img-social" src="../../../../../assets/media/icons/duotune/social-media/link-share-icon.svg" alt="Link Share">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
