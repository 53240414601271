import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { EmpresaDto, EmpresaServiceProxy } from '@shared/service-proxies/service-proxies';
import { EmpresaService } from 'empresa/service/empresa.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-email-dialog',
  templateUrl: './alert-email-dialog.component.html',
  styleUrls: ['./alert-email-dialog.component.scss']
})
export class AlertEmailDialogComponent extends AppComponentBase implements OnInit {

  @Input() funcao: string;
  @Output() onSend = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  frase: string

  constructor(
    public bsModalRef: BsModalRef,
    injector: Injector,
  ) {
    super(injector)
  }



  ngOnInit(): void {
    if(this.funcao == "candidato")
      this.frase = "Deseja enviar um e-mail ao candidato, informando seu avanço no processo seletivo?";
    else if(this.funcao == "gestor")
      this.frase = "Deseja enviar um e-mail ao gestor, sobre os candidatos selecionados? Nesta etapa o gestor e os candidatos receberão um e-mail relacionado à entrevista."
    else if(this.funcao == "naoContratado")
      this.frase = "Deseja enviar um e-mail aos candidatos não contratados, para informá-los sobre o encerramento da vaga?"
  }


  enviar(){
    this.onSend.emit();
    this.bsModalRef.hide();
  }

  close(){
    this.onClose.emit();
    this.bsModalRef.hide();
  }
}
