import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import { CurriculoExperienciaDto, CurriculoExperienciaServiceProxy } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs";

@Component({
  selector: "timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  animations: [appModuleAnimation()],
})
export class TimeLineComponent extends AppComponentBase implements OnInit{

  @Input() experiencias: Array<CurriculoExperienciaDto> = [];
  @Output() alteracaoExperiencia = new EventEmitter();
  exibirLixo: boolean = true;

  constructor(
    injector: Injector,
    private _experienciaService: CurriculoExperienciaServiceProxy,
    private _router: Router,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    if (this._router.url.includes("administrativo") || this._router.url.includes("empresa")) {
      this.exibirLixo = false;
    }
  }

  getTipoTrabalho(value) {
    switch (value) {
      case "0":
        return "Tempo Integral";
      case "1":
        return "Meio período";
      case "2":
        return "Autônomo";
      case "3":
        return "Freelance";
      case "4":
        return "Temporário";
      case "5":
        return "Estágio";
      case "6":
        return "Aprendiz";
      case "7":
        return "Trainee";
      case "8":
        return "Terceirizado";
    }
  }

  getModeloTrabalho(value) {
    switch (value) {
      case "0":
        return "Presencial";
      case "1":
        return "Híbrido";
      case "2":
        return "Home Office";
    }
  }

  delete(experiencia: CurriculoExperienciaDto) {

    this.experiencias.splice(this.experiencias.findIndex(e => e.titulo === experiencia.titulo), 1);
    this.experiencias.sort((a, b) => (a.dataInicio < b.dataInicio) ? 1 : ((b.dataInicio < a.dataInicio) ? -1 : 0))

    abp.ui.setBusy();
    this._experienciaService.delete(experiencia.id).pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      ).subscribe(response => {
        abp.notify.success("Removido com sucesso!");
      });
    this.alteracaoExperiencia.emit(this.experiencias);

  }
}
